<template>
  <div>
    <PopUpPreview ref="previewPopup" :htmlContent="emailHtml" :isPdpa="pdpaEmail" ></PopUpPreview>
    <!--Plugin containers -->
    <!-- <b-button variant="custom-primary" @click="clearField">
      Clear Field
    </b-button> -->

    <div id="stripoSettingsContainer">Loading...</div>
    <div id="stripoPreviewContainer"></div>
  </div>
</template>

<script>
import ExternalDisplayConditionsPopup from "./external-popup.js";
import PopUpPreview from "../automation/component/PopUpPreviw.vue";
export default {
  components: {
    PopUpPreview,
  },
  mounted() {
    this.loadDemoTemplate(this.initPlugin);
  },
  // Utility methods
  props: {
    value: {
      required: false,
      type: String,
    },
    textFloat: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    variableItem: {
      required: false,
    },
    formMain: {
      required: false,
    },
    template: {
      required: false,
      type: Object,
      default() {
        return {
          html: "",
          css: "",
        };
      },
    },
    pdpaEmail: {
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      file: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
      showPreview: false,
      emailHtml: "",
    };
  },
  methods: {
    save() {
      window.StripoApi.getTemplate(function (html, css) {});
    },
    openPreviewPopup() {
      window.StripoApi.compileEmail((error, html) => {
        if (!error) {
          this.emailHtml = html;
          this.$refs.previewPopup.openPopup();
        } else {
          console.error("Error compiling email for preview:", error);
        }
      }, true);
    },
    async compileEmail() {
      this.$bus.$emit("showLoading");
      return new Promise((resolve, reject) => {
        const $ = this;

        return window.StripoApi.compileEmail(
          function (error, compile) {
            let compileHtml = compile;
            window.StripoApi.getTemplate(function (html, css) {
              let template = { html, css };
              $.$emit("onDataChange", { compileHtml, template });
              $.$bus.$emit("hideLoading");
              return resolve({ compileHtml, template });
            });
          },
          true,
          false
        );
      });
    },
    async loadDemoTemplate(callback) {
      const $ = this;
      if ($.template.html == "" && $.template.css == "")
        this.request(
          "GET",
          "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.html",
          null,
          function (html) {
            $.request(
              "GET",
              "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.css",
              null,
              function (css) {
                callback({
                  html: $.template.html || html,
                  css: $.template.css || css,
                });
              }
            );
          }
        );
      else
        callback({
          html: $.template.html,
          css: $.template.css,
        });
    },
    request(method, url, data, callback) {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
          console.error(
            "Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values"
          );
        }
      };
      req.open(method, url, true);
      if (method !== "GET") {
        req.setRequestHeader("content-type", "application/json");
      }
      req.send(data);
    },

    initPlugin(template) {
      const apiRequestData = {
        emailId: "6251361f166bfd9e51e19a03",
        userId: "600a68cbbbc06742e4453517",
        groupId: "test",
      };
      const script = document.createElement("script");
      script.id = "stripoScript";
      script.type = "text/javascript";
      script.src = "https://plugins.stripo.email/static/latest/stripo.js";
      var $ = this;
      script.onload = function () {
        window.Stripo.init({
          settingsId: "stripoSettingsContainer",
          previewId: "stripoPreviewContainer",
          // codeEditorButtonId: "codeEditor",
          emailPreviewButtonId: "codeEditor",
          locale: "en",
          html: template.html,
          css: template.css,
          apiRequestData: apiRequestData,
          conditionsEnabled: true,
          customConditionsEnabled: false,
          conditionsTooltip:
            ExternalDisplayConditionsPopup.getConditionsTooltip,
          conditionCategories: [
            {
              type: "EXTERNAL",
              category: "Complex conditions",
              openExternalDisplayConditionsDialog:
                ExternalDisplayConditionsPopup.openExternalDisplayConditionsDialog,
              useContextActionsInsteadOfSettingsTab: true,
            },
          ],
          mergeTags: [
            {
              category: "MailChimp",
              entries: $.variableItem,
            },
          ],

          getAuthToken: function (callback) {
            $.axios
              .post("https://plugins.stripo.email/api/v1/auth", {
                pluginId: "2d866fa0fd8e43dbaff4ffd219c09531",
                secretKey: "60faabfe0dcb43a7bfe016cbd7568830",
              })
              .then(({ data }) => {
                callback(data.token);
              });
          },
        });
      };
      document.body.appendChild(script);
    },
    clearField() {
      const $ = this;
      this.request(
        "GET",
        "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.html",
        null,
        function (html) {
          $.request(
            "GET",
            "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Empty-Template/Empty-Template.css",
            null,
            async function (css) {
              await $.initPlugin({
                html: html,
                css: css,
              });
              $.compileEmail();
            }
          );
        }
      );
    },
  },
};
</script>

<style>
.nav-tabs.nav-justified {
  display: block !important;
}
#stripoSettingsContainer {
  width: 400px;
  float: left;
  height: 80vh !important;
  max-height: 100% !important;
  overflow: auto;
}
#stripoPreviewContainer {
  width: calc(100% - 400px);
  float: left;
}




</style>
