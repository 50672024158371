import Vue from "vue";
import axios from "axios";

import apiPath from "@/plugins/api";
export const state = () => ({
  state: {
    modalAlertLogin: false,
    memberTierList: {},
    createdMemberTier: {},
    dropdown: {},
    detailMember: {},
    updateMember: {},
    deleteResp: {},
  },
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setMemberTierList: async function (state, val) {
    state.memberTierList = val;
  },
  setCreateMemberTier: async function (state, val) {
    state.createdMemberTier = val;
  },
  setDropdown: async function (state, val) {
    state.dropdown = val;
  },
  setDetailMember: async function (state, val) {
    state.detailMember = val;
  },
  setUpdateMember: async function (state, val) {
    state.updateMember = val;
  },
  setDeleteMemberTier: async function (state, val) {
    state.deleteResp = val;
  },
};

export const actions = {
  getMemberTierList: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/MemberLevel/getlist`, payload).then(
      async (data) => {
        commit("setMemberTierList", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  createdMemberTier: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/MemberLevel`, payload).then(
      async (data) => {
        commit("setCreateMemberTier", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getDropdown: async ({ commit }) => {
    await axios.get(`${apiPath}/MemberLevel/getmemberlevel`).then(
      async (data) => {
        commit("setDropdown", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getDetailMember: async ({ commit }, payload) => {
    await axios.get(`${apiPath}/MemberLevel/${payload}`).then(
      async (data) => {
        commit("setDetailMember", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateMemberTier: async ({ commit }, payload) => {
    await axios.put(`${apiPath}/MemberLevel/${payload.id}`, payload.form).then(
      async (data) => {
        commit("setUpdateMember", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  deleteMemberTier: async ({ commit }, payload) => {
    await axios.delete(`${apiPath}/MemberLevel/${payload}`).then(
      async (data) => {
        commit("setDeleteMemberTier", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};

export default {
  state,
  actions,
  mutations,
};
