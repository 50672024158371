<template>
  <div class="footer-action">
    <b-col>
      <router-link v-if="routePath" :to="routePath">
        <b-button class="btn-cancel btn-width">Cancel</b-button>
      </router-link>
    </b-col>
    <b-col class="text-right" v-if="!hideSubmit">
      <b-button class="main-color btn-save" @click="$emit('submit')">
        Save
      </b-button>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    hideSubmit: {
      required: false,
      default: false,
    },
    routePath: {
      required: false,
    },
  },
  created() {},
  destroyed() {},
  methods: {

  },
};
</script>

<style>
@media (min-width: 200px) and (max-width: 414px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.footer-action {
  box-shadow: 0px 5px 0px black, 0px 5px 10px black;
  margin-left: var(--margin-spacing);
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}
</style>
